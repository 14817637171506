import React, { useState } from "react";
import Modal from "../../compenents/modal/modal";
import "./scss/add-competitor.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Input from "../../compenents/inputs/input-simple";
import { Grid } from "@material-ui/core";
import { addHotel } from "../../actions/hotelList.action";

import { useFormik } from "formik";
import * as Yup from "yup";

function AddHotel(props) {
  const { dialogClose, open } = props;

  const validateSchema = Yup.object().shape({
    name: Yup.string().required("This field is required"),
    url: Yup.string()
      .url("Please enter a valid url")
      .required("This field is required"),
    pms: Yup.string().required("This field is required"),
    website: Yup.string()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        "Enter correct url!"
      )
      .notRequired("Please enter website"),
    propertyId: Yup.string().when("pms", (pms, field) => {
      if (["Redistay", "SynXis-whr", "SynXis-whg"]?.includes(pms?.[0])) {
        return field.required("This field is required");
      } else {
        return field.notRequired();
      }
    }),
    aisCode: Yup.string().when("pms", (pms, field) => {
      if (pms?.[0] === "AIS") {
        return field.required("This field is required");
      } else {
        return field.notRequired();
      }
    }),

    phoneNumber: Yup.string().notRequired(),
    address: Yup.string("Please enter a valid email").notRequired(
      "This field is required"
    ),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      url: "",
      pms: "",
      website: "",
      phoneNumber: "",
      address: "",
      propertyId: "",
      aisCode: "",
      asiType: "",
    },
    enableReinitialize: true,
    validationSchema: validateSchema,
    onSubmit: (values, {}) => {
      submitHandler(values);
    },
  });

  const submitHandler = (values) => {
    const {
      name,
      url,
      pms,
      propertyId,
      website,
      phoneNumber,
      address,
      aisCode,
      asiType
    } = values;
    props
      .addHotel({
        name,
        url,
        PMS_Used: pms,
        Property_Id: propertyId,
        description: "",
        Website: website,
        PhoneNumber: phoneNumber,
        Address: address,
        AisCode: aisCode,
        AsiType: asiType ? asiType : 0,
      })
      .then((res) => {
        props.dialogClose();
        props.fetchHotelListData(true);
      });
  };

  return (
    <Modal
      open={open}
      onClose={dialogClose}
      class="sm"
      fullWidth="true"
      modalHeading="Add Hotel"
      modalSaveTxt="Save"
      modalCloseTxt="Cancel"
      onClick={formik.handleSubmit}
      showActionBtn
      savebtnClass="createBtn"
      primary
    >
      <div>
        <Grid
          xs={10}
          container
          className="subtaskrow-1"
          style={{ margin: "8px 0px" }}
        >
          <Grid xs={3} className="label" item style={{ margin: "15px 0px" }}>
            Name
          </Grid>
          <Grid xs={7} item>
            <Input
              name="name"
              type="text"
              label="Name"
              className="txtarea"
              touched={formik.touched.name}
              onChange={formik.handleChange}
              value={formik.values.name}
              valid={!Boolean(formik.touched.name && formik.errors.name)}
              error={formik.touched.name && formik.errors.name}
            />
          </Grid>
          <Grid xs={3} className="label" item style={{ margin: "15px 0px" }}>
            Add Url
          </Grid>
          <Grid xs={7} item>
            <Input
              name="url"
              type="text"
              label="Enter Url"
              className="txtarea"
              onChange={formik.handleChange}
              value={formik.values.url}
              valid={!Boolean(formik.touched.url && formik.errors.url)}
              error={formik.touched.url && formik.errors.url}
              touched={formik.touched.url}
            />
          </Grid>
          <Grid xs={3} className="label" item style={{ margin: "15px 0px" }}>
            PMS
          </Grid>
          <Grid xs={7} item>
            <Input
              name="pms"
              type="select"
              label="Select PMS"
              className="txtarea"
              // valid={!pmsError}
              // valid={!pmsError}
              items={[
                { key: "Other", value: "Other" },
                { key: "Redistay", value: "Redistay" },
                { key: "SynXis-whg", value: "SynXis-whg" },
                { key: "SynXis-whr", value: "SynXis-whr" },
                { key: "ASI", value: "ASI" },
              ]}
              onChange={formik.handleChange}
              value={formik.values.pms}
              valid={!Boolean(formik.touched.pms && formik.errors.pms)}
              error={formik.touched.pms && formik.errors.pms}
              touched={formik.touched.pms}
            />
          </Grid>
          {["Redistay", "SynXis-whr", "SynXis-whg"]?.includes(
            formik?.values?.pms
          ) && (
            <>
              <Grid
                xs={3}
                className="label"
                item
                style={{ margin: "15px 0px" }}
              >
                Property ID
              </Grid>
              <Grid xs={7} item>
                <Input
                  name="propertyId"
                  type="text"
                  label="Enter Property ID"
                  className="txtarea"
                  onChange={formik.handleChange}
                  value={formik.values.propertyId}
                  valid={
                    !Boolean(
                      formik.touched.propertyId && formik.errors.propertyId
                    )
                  }
                  error={formik.touched.propertyId && formik.errors.propertyId}
                  touched={formik.touched.propertyId}
                />
              </Grid>
            </>
          )}

          {formik.values.pms === "ASI" && (
            <>
              <Grid
                xs={3}
                className="label"
                item
                style={{ margin: "15px 0px" }}
              >
                ASI Code
              </Grid>
              <Grid xs={7} item>
                <Input
                  name="aisCode"
                  type="text"
                  label="Enter ASI Code"
                  className="txtarea"
                  onChange={formik.handleChange}
                  value={formik.values.aisCode}
                  valid={
                    !Boolean(formik.touched.aisCode && formik.errors.aisCode)
                  }
                  error={formik.touched.aisCode && formik.errors.aisCode}
                  touched={formik.touched.aisCode}
                />
              </Grid>
              <Grid
                xs={3}
                className="label"
                item
                style={{ margin: "15px 0px" }}
              >
                ASI Version
              </Grid>
              <Grid xs={7} item>
                <Input
                  name="asiType"
                  type="select"
                  label="Select AIS Version"
                  className="txtarea"
                  items={[
                    { key: "6.x", value: 6 },
                    { key: "7.x", value: 7 },
                  ]}
                  onChange={formik.handleChange}
                  value={formik.values.asiType}
                  valid={
                    !Boolean(
                      formik.touched.asiType && formik.errors.asiType
                    )
                  }
                  error={formik.touched.asiType && formik.errors.asiType}
                  touched={formik.touched.asiType}
                />
              </Grid>
            </>
          )}
          <Grid xs={3} className="label" item style={{ margin: "15px 0px" }}>
            Add Website
          </Grid>
          <Grid xs={7} item>
            <Input
              name="website"
              type="text"
              label="Enter Website"
              className="txtarea"
              onChange={formik.handleChange}
              value={formik.values.website}
              valid={!Boolean(formik.touched.website && formik.errors.website)}
              error={formik.touched.website && formik.errors.website}
              touched={formik.touched.website}
            />
          </Grid>
          <Grid xs={3} className="label" item style={{ margin: "15px 0px" }}>
            Add Phone Number
          </Grid>
          <Grid xs={7} item>
            <Input
              name="phoneNumber"
              type="text"
              label="Enter PhoneNumber"
              className="txtarea"
              onChange={formik.handleChange}
              value={formik.values.phoneNumber}
              valid={
                !Boolean(
                  formik.touched.phoneNumber && formik.errors.phoneNumber
                )
              }
              error={formik.touched.phoneNumber && formik.errors.phoneNumber}
              touched={formik.touched.phoneNumber}
            />
          </Grid>
          <Grid xs={3} className="label" item style={{ margin: "15px 0px" }}>
            Add Address
          </Grid>
          <Grid xs={7} item>
            <Input
              name="address"
              type="text"
              label="Enter Address"
              className="txtarea"
              onChange={formik.handleChange}
              value={formik.values.address}
              valid={!Boolean(formik.touched.address && formik.errors.address)}
              error={formik.touched.address && formik.errors.address}
              touched={formik.touched.address}
            />
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
}
const mapStateToProps = (state) => ({
  user: state.user.userData,
});
const mapDispatchToProps = (dispatch) => ({
  addHotel: bindActionCreators(addHotel, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(AddHotel);
