import React, { Component } from "react";
import UserModal from "../Modal";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchHotelListData } from "../../actions/hotelList.action";
import { refreshHotelPrice } from "../../actions/editHotel.action";
import { Link } from "react-router-dom";
import { PATH_URL } from "../../constants/route.constants";
import { createUrl } from "../../common";
import AddCompetitor from "./add-competitor";
import AddHotel from "./add-hotel";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Search from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import SettingsIcon from "@material-ui/icons/Settings";
import Tooltip from "@material-ui/core/Tooltip";
import HotelSettings from "./hotel-settings";

class HotelList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addCompetitorDialogState: false,
      addHotelDialogState: false,
      hotelSettingsDialogState: false,
      order: null,
      orderBy: null,
      filterFn: {
        fn: (items) => items,
      },
      selectedHotel: null,
    };
    this.headerColumns = [
      "Competitor 1",
      "Competitor 2",
      "Competitor 3",
      "Competitor 4",
      "Competitor 5",
    ];
  }

  componentDidMount() {
    const { fetchHotelListData } = this.props;
    fetchHotelListData(true);
  }

  addCompetitorHandler = () => {
    this.setState((prevState) => ({
      addCompetitorDialogState: !prevState.addCompetitorDialogState,
    }));
  };

  addHotelHandler = () => {
    this.setState((prevState) => ({
      addHotelDialogState: !prevState.addHotelDialogState,
    }));
  };

  hotelSettingsHandler = () => {
    this.setState((prevState) => ({
      hotelSettingsDialogState: !prevState.hotelSettingsDialogState,
    }));
  };

  redirectHandler = (hotel) => {
    let url = createUrl(
      PATH_URL.HOTEL_EDIT,
      { name: hotel.name },
      { id: hotel.id }
    );
    return url;
  };

  handleSort = (key) => {
    const isAsc = this.state.orderBy === key && this.state.order === "asc";
    this.setState({
      order: isAsc ? "desc" : "asc",
      orderBy: key,
    });
  };

  stableSort = (array, comparator) => {
    if (!this.state.orderBy) {
      return array;
    }
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => this.descendingComparator(a, b, orderBy)
      : (a, b) => -this.descendingComparator(a, b, orderBy);
  };

  descendingComparator = (a, b, orderBy) => {
    return a.tableData[orderBy].localeCompare(b.tableData[orderBy]);
  };

  handleSearch = (e) => {
    const value = e.target.value;
    this.setState({
      filterFn: {
        fn: (items) => {
          if (value === "") return items;
          else
            return items.filter((x) =>
              x.tableData.name.toLowerCase().includes(value?.toLowerCase())
            );
        },
      },
    });
  };

  openSettings = (hotel) => {
    this.setState({
      selectedHotel: hotel,
      hotelSettingsDialogState: true,
    });
  };

  handleRefresh = (hotel) => {
    this.props.refreshHotelPrice(hotel.id, true);
  };

  render() {
    const {
      addCompetitorDialogState,
      addHotelDialogState,
      hotelSettingsDialogState,
    } = this.state;
    const { hotelListData } = this.props;
    return (
      <div className="hotel-list-container">
        <div className="container">
          <div className="row">
            <div className="col-md-4 mb-2">
              <TextField
                label="Search by hotel name"
                variant="outlined"
                className="w-100"
                onChange={this.handleSearch}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="col-md-8">
              <div className="button-wrapper">
                <button
                  className="btn add-competitor"
                  onClick={this.addCompetitorHandler}
                >
                  Add Competitor
                </button>
                <button
                  className="btn add-hotel"
                  onClick={this.addHotelHandler}
                >
                  Add Hotel
                </button>
              </div>
            </div>
            {addHotelDialogState && (
              <AddHotel
                open={addHotelDialogState}
                dialogClose={this.addHotelHandler}
                fetchHotelListData={fetchHotelListData}
              />
            )}
            {addCompetitorDialogState && (
              <AddCompetitor
                open={addCompetitorDialogState}
                dialogClose={this.addCompetitorHandler}
              />
            )}
            <HotelSettings
              open={hotelSettingsDialogState}
              dialogClose={this.hotelSettingsHandler}
              hotel={this.state.selectedHotel}
            />
            <TableContainer component={Paper} style={{ maxHeight: "75vh" }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow style={{ backgroundColor: "#4C337B" }}>
                    <TableCell
                      sortDirection={
                        this.state.orderBy === "name" ? this.state.order : "asc"
                      }
                    >
                      <TableSortLabel
                        active={this.state.orderBy === "name"}
                        direction={
                          this.state.orderBy === "name"
                            ? this.state.order
                            : "asc"
                        }
                        onClick={() => this.handleSort("name")}
                      >
                        <strong style={{ fontSize: 16 }}>Hotel</strong>
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      sortDirection={
                        this.state.orderBy === "competitor1"
                          ? this.state.order
                          : "asc"
                      }
                    >
                      <TableSortLabel
                        active={this.state.orderBy === "competitor1"}
                        direction={
                          this.state.orderBy === "competitor1"
                            ? this.state.order
                            : "asc"
                        }
                        onClick={() => this.handleSort("competitor1")}
                      >
                        <strong style={{ fontSize: 16 }}>Competitor 1</strong>
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      sortDirection={
                        this.state.orderBy === "competitor2"
                          ? this.state.order
                          : "asc"
                      }
                    >
                      <TableSortLabel
                        active={this.state.orderBy === "competitor2"}
                        direction={
                          this.state.orderBy === "competitor2"
                            ? this.state.order
                            : "asc"
                        }
                        onClick={() => this.handleSort("competitor2")}
                      >
                        <strong style={{ fontSize: 16 }}>Competitor 2</strong>
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      sortDirection={
                        this.state.orderBy === "competitor3"
                          ? this.state.order
                          : "asc"
                      }
                    >
                      <TableSortLabel
                        active={this.state.orderBy === "competitor3"}
                        direction={
                          this.state.orderBy === "competitor3"
                            ? this.state.order
                            : "asc"
                        }
                        onClick={() => this.handleSort("competitor3")}
                      >
                        <strong style={{ fontSize: 16 }}>Competitor 3</strong>
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      sortDirection={
                        this.state.orderBy === "competitor4"
                          ? this.state.order
                          : "asc"
                      }
                    >
                      <TableSortLabel
                        active={this.state.orderBy === "competitor4"}
                        direction={
                          this.state.orderBy === "competitor4"
                            ? this.state.order
                            : "asc"
                        }
                        onClick={() => this.handleSort("competitor4")}
                      >
                        <strong style={{ fontSize: 16 }}>Competitor 4</strong>
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      sortDirection={
                        this.state.orderBy === "competitor5"
                          ? this.state.order
                          : "asc"
                      }
                    >
                      <TableSortLabel
                        active={this.state.orderBy === "competitor5"}
                        direction={
                          this.state.orderBy === "competitor5"
                            ? this.state.order
                            : "asc"
                        }
                        onClick={() => this.handleSort("competitor5")}
                      >
                        <strong style={{ fontSize: 16 }}>Competitor 5</strong>
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <strong style={{ fontSize: 16 }}>Actions</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {hotelListData &&
                    this.stableSort(
                      this.state.filterFn.fn(hotelListData),
                      this.getComparator(this.state.order, this.state.orderBy)
                    ).map((row) => (
                      <TableRow key={row.id}>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ fontSize: 14 }}
                        >
                          <Link to={this.redirectHandler(row)}>
                            {row.tableData.name}
                          </Link>
                        </TableCell>
                        <TableCell style={{ fontSize: 14 }}>
                          {row.tableData.competitor1}
                        </TableCell>
                        <TableCell style={{ fontSize: 14 }}>
                          {row.tableData.competitor2}
                        </TableCell>
                        <TableCell style={{ fontSize: 14 }}>
                          {row.tableData.competitor3}
                        </TableCell>
                        <TableCell style={{ fontSize: 14 }}>
                          {row.tableData.competitor4}
                        </TableCell>
                        <TableCell style={{ fontSize: 14 }}>
                          {row.tableData.competitor5}
                        </TableCell>
                        <TableCell>
                          <Tooltip title="Settings">
                            <IconButton
                              color="primary"
                              onClick={() => this.openSettings(row)}
                            >
                              <SettingsIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  hotelListData: state.hotelListReducer.list?.map((v) => ({
    ...v,
    tableData: {
      name: v.name || "",
      competitor1: v.competitors[0]?.name || "",
      competitor2: v.competitors[1]?.name || "",
      competitor3: v.competitors[2]?.name || "",
      competitor4: v.competitors[3]?.name || "",
      competitor5: v.competitors[4]?.name || "",
    },
  })),
});

const mapDispatchToProps = (dispatch) => ({
  fetchHotelListData: bindActionCreators(fetchHotelListData, dispatch),
  refreshHotelPrice: bindActionCreators(refreshHotelPrice, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HotelList);
