import React, { Component } from "react";
import { connect } from "react-redux";
import ToggleButton from "react-toggle-button";
import "react-tagsinput/react-tagsinput.css";
import { bindActionCreators } from "redux";
import {
  refreshHotelPrice,
  fetchCompetitorsList,
  fetchHotelData,
  updateHotelEditData,
  uploadOccupancy,
  updateHotelStatus,
  deleteHotel,
} from "../../actions/editHotel.action";
import Autocomplete from "./autoSuggest";
import { queryStringToObject } from "../../common";
import { PATH_URL } from "../../constants/route.constants";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Tooltip from "@material-ui/core/Tooltip";

class editHotel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hotelName: "",
      hotelId: "",
      propId: "",
      pmsValue: "",
      url: "",
      website: "",
      phoneNumber: "",
      address: "",
      userName: "",
      password: "",
      aisCode: "",
      asiType: "",
      competitorList: [],
      selectedCompetitor: [],
      selectedCompetitorID: [],
      selectedCompetitorName: [],
      // Initially, no file is selected
      selectedFile: null,
    };
  }
  componentDidMount() {
    const {
      match: {
        params: { name },
      },
    } = this.props;
    let query = queryStringToObject(this.props.location.search);
    this.setState({
      hotelName: name,
      hotelId: query.id,
    });
    this.props.fetchHotelData(query.id, true).then(() => {
      this.props.fetchCompetitorsList(true).then(() => {
        const {
          editHotelData: { data = {}, competitorsList = [] },
        } = this.props;
        let compObj = {
          id: [],
          name: [],
        };
        if (data.competitors) {
          data.competitors.forEach((item, index) => {
            compObj.id.push(item.id);
            compObj.name.push(item.name);
          });
        }
        const stateObj = {
          hotelName: data.name,
          pmsValue: data.pmS_Used || 0,
          userName: data.user_Id,
          password: data.password,
          selectedCompetitor: data.competitors,
          selectedCompetitorID: compObj.id,
          selectedCompetitorName: compObj.name,
          propId: data.property_Id,
          hotelStatus: data.status,
          url: data.url,
          website: data.website,
          phoneNumber: data.phoneNumber,
          address: data.address,
          aisCode: data?.aisCode,
          asiType: data?.asiType ,
        };
        if (
          competitorsList.length > 1 &&
          competitorsList !== this.state.competitorList
        ) {
          stateObj.competitorList = competitorsList;
        }
        this.setState({ ...stateObj });
      });
    });
  }

  // static getDerivedStateFromProps = (props, state) => {

  // }

  onInputChange = (e, type) => {
    switch (type) {
      case "hName":
        this.setState({
          hotelName: e.target.value,
        });
        break;
      case "pmsValue":
        this.setState({
          pmsValue: e.target.value,
        });
        if (e.target.value === "ASI") {
          this.setState({
            aisCode: "",
          });
        }
        break;
      // case 'userName':
      //   this.setState({
      //     userName: e.target.value
      //   });
      //   break;
      // case 'password':
      //   this.setState({
      //     password: e.target.value
      //   });
      //   break;
      case "selectCompetitor":
        this.setState({
          selectedCompetitorName: e,
        });
        break;
      case "URL":
        this.setState({
          url: e.target.value,
        });
        break;
      case "website":
        this.setState({
          website: e.target.value,
        });
        break;
      case "phoneNumber":
        this.setState({
          phoneNumber: e.target.value,
        });
        break;
      case "address":
        this.setState({
          address: e.target.value,
        });
        break;
      case "aisCode":
        this.setState({
          aisCode: e.target.value,
        });
        break;
      case "asiType":
        this.setState({
          asiType: e.target.value,
        });
        break;
      default:
        break;
    }
  };
  handleBtn = (e, isSubmit = false) => {
    e.preventDefault();

    let data = {
      Name: this.state.hotelName,
      Property_Id: this.state.propId,
      User_Id: this.state.userName,
      Password: this.state.password,
      URL: this.state.url,
      PMS_Used: this.state.pmsValue,
      Competitors: this.state.selectedCompetitorID,
      Website: this.state.website,
      PhoneNumber: this.state.phoneNumber,
      Address: this.state.address,
      AisCode: this.state?.aisCode,
      AsiType: this.state?.asiType ? parseInt(this.state?.asiType) : 0,
    };

    if (isSubmit) {
      this.props
        .updateHotelEditData(this.state.hotelId, data, true)
        .then((status) => {
          status && this.props.history.push(PATH_URL.HOTEL_LIST);
        });
    } else {
      this.props.history.push(PATH_URL.HOTEL_LIST);
    }
  };

  handleRefresh = (e) => {
    debugger;
    this.props.refreshHotelPrice(this.state.hotelId, true);
  };

  setCompetitorsId = (id) => {
    let selectedCompetitorID = this.state.selectedCompetitorID;
    if (!selectedCompetitorID.includes(id)) {
      selectedCompetitorID.push(id);
      this.setState({
        selectedCompetitorID,
      });
    }
  };

  deleteCompetitorsId = (index) => {
    let selectedCompetitorID = [...this.state.selectedCompetitorID];
    selectedCompetitorID.splice(index, 1);
    this.setState({
      selectedCompetitorID,
    });
  };

  // On file select (from the pop up)
  onFileChange = (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
  };

  // On file upload (click the upload button)
  onFileUpload = () => {
    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append(
      "file",
      this.state.selectedFile,
      this.state.selectedFile.name
    );

    // Details of the uploaded file
    console.log(this.state.selectedFile);

    // Request made to the backend api
    // Send formData object
    this.props.uploadOccupancy(formData);
  };

  // File content to be displayed after
  // file upload is complete
  fileData = () => {
    if (this.state.selectedFile) {
      return (
        <div>
          <h2>File Details:</h2>
          <p>File Name: {this.state.selectedFile.name}</p>
          <p>File Type: {this.state.selectedFile.type}</p>
          <p>
            Last Modified:{" "}
            {this.state.selectedFile.lastModifiedDate.toDateString()}
          </p>
        </div>
      );
    } else {
      return (
        <div>
          <br />
          <a href="/sample.csv" download>
            Click to download Sample CSV
          </a>
        </div>
      );
    }
  };

  handleToggle = async (value) => {
    const query = queryStringToObject(this.props.location.search);
    await this.props.updateHotelStatus(query.id, {
      status: !value,
    });
    await this.props.fetchHotelData(query.id, true);
    this.setState({
      hotelStatus: !value,
    });
  };

  deleteHotel = async () => {
    if (
      window.confirm("Are you sure you want to delete this hotel permanently?")
    ) {
      console.log(this.props.editHotelData);
      this.props.deleteHotel(this.props.editHotelData.data.id).then(() => {
        this.props.history.push(PATH_URL.HOTEL_LIST);
      });
    }
  };

  render() {
    const {
      match: {
        params: { name },
      },
    } = this.props;
    return (
      <div>
        {this.state.pmsValue == "Other" ? (
          <div className="edit-hotel">
            <h3>Upload Occupancy</h3>
            <div>
              <input accept=".csv" type="file" onChange={this.onFileChange} />
              <button
                disabled={!this.state.selectedFile}
                onClick={this.onFileUpload}
              >
                Upload!
              </button>
            </div>
            {this.fileData()}
          </div>
        ) : null}

        <div className="edit-hotel">
          <div class="row" style={{ paddingBottom: "25px" }}>
            <div class="column" style={{ width: "75%" }}>
              <h3>
                {name}
                <br />
                <code style={{ fontSize: 14 }}>
                  {this.props.editHotelData.data.id}
                </code>
              </h3>
            </div>
            <div
              style={{
                width: "40px",
                height: "40px",
                marginRight: "40px",
                cursor: "pointer",
                display: "none",
              }}
              onClick={() => this.handleRefresh()}
            >
              <p>
                <img alt="Refresh Price" src="/images/refresh.png" />
              </p>
            </div>

            <div class="column" style={{ marginTop: "9px" }}>
              <ToggleButton
                value={this.state.hotelStatus}
                onToggle={this.handleToggle}
              />
            </div>
            <div
              class="column"
              style={{ marginTop: "-5px", marginLeft: "50px" }}
            >
              <Tooltip title="Delete">
                <IconButton
                  aria-label="delete"
                  style={{ color: "rgb(220, 0, 78)" }}
                  onClick={this.deleteHotel}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>
          <form>
            <ul>
              <li className={"inputField"}>
                <label>Hotel Name</label>
                <input
                  type="text"
                  id="hName"
                  name="Hotel Name"
                  onChange={(e) => {
                    this.onInputChange(e, "hName");
                  }}
                  value={this.state.hotelName}
                />
              </li>
              <li className={"inputField"}>
                <label>PMS used</label>
                <select
                  isOptionDisabled="yes"
                  onChange={(e) => {
                    this.onInputChange(e, "pmsValue");
                  }}
                  value={this.state.pmsValue}
                >
                  <option selected value="Redistay">
                    Redistay
                  </option>
                  <option value="SynXis-whr">SynXis-whr</option>
                  <option value="SynXis-whg">SynXis-whg</option>
                  <option value="ASI">ASI</option>
                  <option value="Other">Other</option>
                </select>
              </li>

              {this.state.pmsValue === "ASI" && (
                <>
                  <li className={"inputField inputUrlField"}>
                    <label>ASI Code</label>
                    <input
                      type="text"
                      id="aisCode"
                      name="aisCode"
                      onChange={(e) => {
                        this.onInputChange(e, "aisCode");
                      }}
                      value={this.state.aisCode}
                    />
                  </li>
                  <li className={"inputField inputUrlField"}>
                    <label>ASI Version</label>
                    <select
                      id="asiType"
                      name="asiType"
                      className="txtarea"
                      value={this.state.asiType}
                      onChange={(e) => {
                        this.onInputChange(e, "asiType");
                      }}
                    >
                      <option value={6}>6.x</option>
                      <option value={7}>7.x</option>
                    </select>
                  </li>
                </>
              )}
              {/* <li className={'inputField'}>
                <label>Login ID</label>
                <input type="text" id="userName" name="Login ID" onChange={(e) => { this.onInputChange(e, 'userName') }} value={this.state.userName} />
              </li>
              <li className={'inputField'}>
                <label>Password</label>
                <input type="password" id="pwd" name="Password" onChange={(e) => { this.onInputChange(e, 'password') }} value={this.state.password} />
              </li> */}
              <li className={"inputField inputUrlField"}>
                <label>URL</label>
                <input
                  type="text"
                  id="url"
                  name="URL"
                  onChange={(e) => {
                    this.onInputChange(e, "URL");
                  }}
                  value={this.state.url}
                />
              </li>

              <li className={"inputField inputUrlField"}>
                <label>Website</label>
                <input
                  type="text"
                  id="website"
                  name="website"
                  onChange={(e) => {
                    this.onInputChange(e, "website");
                  }}
                  value={this.state.website}
                />
              </li>

              <li className={"inputField inputUrlField"}>
                <label>Phone Number</label>
                <input
                  type="text"
                  id="phoneNumber"
                  name="phoneNumber"
                  onChange={(e) => {
                    this.onInputChange(e, "phoneNumber");
                  }}
                  value={this.state.phoneNumber}
                />
              </li>

              <li className={"inputField inputUrlField"}>
                <label>Address</label>
                <input
                  type="text"
                  id="address"
                  name="address"
                  onChange={(e) => {
                    this.onInputChange(e, "address");
                  }}
                  value={this.state.address}
                />
              </li>

              <li className={"selectCompetitor"}>
                <h5>Competitors</h5>

                {this.state.competitorList.length > 1 && (
                  <Autocomplete
                    competitorList={this.state.competitorList}
                    selectedCompetitorName={this.state.selectedCompetitorName}
                    deleteCompetitorsId={this.deleteCompetitorsId}
                    setCompetitorsId={this.setCompetitorsId}
                    onInputChange={this.onInputChange}
                  />
                )}
              </li>
            </ul>
            <div className={"Btn-group"}>
              <button
                className="cancel"
                onClick={(e) => {
                  this.handleBtn(e, false);
                }}
              >
                Cancel
              </button>
              <button
                className="save"
                onClick={(e) => {
                  this.handleBtn(e, true);
                }}
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  editHotelData: state.editHotelReducer,
});

const mapDispatchToProps = (dispatch) => ({
  fetchHotelData: bindActionCreators(fetchHotelData, dispatch),
  fetchCompetitorsList: bindActionCreators(fetchCompetitorsList, dispatch),
  updateHotelEditData: bindActionCreators(updateHotelEditData, dispatch),
  uploadOccupancy: bindActionCreators(uploadOccupancy, dispatch),
  updateHotelStatus: bindActionCreators(updateHotelStatus, dispatch),
  refreshHotelPrice: bindActionCreators(refreshHotelPrice, dispatch),
  deleteHotel: bindActionCreators(deleteHotel, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(editHotel);
